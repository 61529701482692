import ProjectCard from "../components/card/projectcard";

export default function Projects(){
    return<div className="h-screen">
        <div className="lg:grid lg:grid-cols-3">
   
        <a href="https://packfresh.net"  rel="noreferrer" target={'_blank'}>
        <ProjectCard title={'Pack Fresh'} desc={"Packfresh.net is a website that allow you to buy Magic the Gathering Cards with Cryptocurrency or Stripe. It relies on nodemailer and mailcow to relay emails to customers. "} css={'pf'}/>
        </a>
        <a href="https://familymarketing.us"  rel="noreferrer" target={'_blank'}>
        <ProjectCard title={'US Family Marketing'} desc={'US Family Marketing is a website that offers digital marketing services to clients across the United States.'} css={'fm'}/>
        </a>
        <a href="https://hempjuiceusa.com"  rel="noreferrer" target={'_blank'}>
        <ProjectCard title={'HempJuiceUSA'} desc={'HempJuiceUSA.com is an organic health food website selling hemp related products. It focuses on using Shopify to manage inventory and allows customers anywhere in the world to purchase natural remedys. '} css={'fm'}/>
        </a>

        </div>
    </div>
}