import Nav from "../nav";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../../pages/home";
import Contact from "../../pages/contact";
import Projects from "../../pages/projects";
import About from "../../pages/about";

export default function Router() {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center h-screen ">
          <div className="loader"></div>
        </div>
      }
    >
      <Nav />
      <Routes>
      <Route path="*" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/about" element={<About />} />

      </Routes>
    </Suspense>
  );
}
