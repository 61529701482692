import { SocialIcon } from 'react-social-icons';


export default function Footer(){
    return<div className="w-full bg-blue-400 lg:grid lg:grid-cols-3 ">
        <div></div>
        <div className="m-auto p-2">
            <div className="inline-block m-1"><SocialIcon url="https://www.linkedin.com/in/aj-novobilski-122296aa" /></div>
            <div className="inline-block m-1"><SocialIcon url="https://www.facebook.com/novobilski" /></div>

        </div>
        <div></div>

        
    </div>
}