import { Link } from "react-router-dom";
import aj from "../assets/a.jpg";

export default function About() {
  return (
    <div className="m-auto h-full grid">
        <h1 className="p-5 text-center font-bold text-2xl">About Me</h1>
      <img className="h-64 rounded-xl m-auto" src={aj} />
      <button className="p-3 mt-5 bg-blue-500 rounded-lg text-white m-auto hover:bg-blue-300 hover:text-black">
      <Link to="/resume.pdf" target="_blank" download>Resume Download</Link>
      </button>
      <div className="p-5 border m-5">
      <p>My name is Andrew Novobilski and I am a Full Stack Web Developer. I started learning the Mern stack at University of California Irvine in 2021, and have applied it to many projects I've worked on.
      I am a passionate web developer with experience in many kinds of web development. I consider web design to be my true passion, and I wish to find a career where I can apply my skills. </p>
      <p>I am a student at Cal State Fullerton pursueing a degree in marketing & info systems. Currently I am seeking <b>full-time employment</b>.  I am also available to work on weekends.</p>
      <br/>
      <p>My list of skills includes:
        <ul>
            <li>Mern Stack</li>
            <li>Apache2</li>
            <li>Nginx</li>
            <li>Mailcow</li>
            <li>Linux</li>
            <li>React</li>
            <li>NodeJS</li>
            <li>GraphQL</li>
            <li>WordPress</li>
            <li>MongoDB</li>
            <li>Adobe Suite</li>

        </ul>
      </p>
      
      </div>
      <div className="text-center">
        <Link to={'/contact'}>
      <button className="p-3 m-3 bg-blue-500 rounded-lg text-white hover:bg-blue-300 hover:text-black">Contact Me</button>
      </Link>
      </div>
    </div>
  );
}
