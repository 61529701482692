import { useState } from "react";

import Menu from "./menu";

import { Link } from "react-router-dom";


export default function MobileNav(props) {
  const [open, setopen] = useState(false);

  return (
    <header className="bg-white sticky top-0 z-50 ">
      <div className="grid grid-cols-2  border-2">
        <div className="p-2 mt-3" onClick={() => setopen(!open)}>
          <Menu />
        </div>
        <div className=" ">
    
        </div>
      </div>
      <ul className="grid divide-y-2 divide-slate-300  ">
        {open
          ? <div>
                <Link to={'/home'}>
                  <li onClick={() => setopen(false)} className=" p-2">
                    Home
                  </li>
                </Link>
                <Link to={'/about'}>
                  <li onClick={() => setopen(false)} className=" p-2">
                    About
                  </li>
                </Link>
                <Link to={'/projects'}>
                  <li onClick={() => setopen(false)} className=" p-2">
                    Projects
                  </li>
                </Link>
                <Link to={'/contact'}>
                  <li onClick={() => setopen(false)} className=" p-2">
                    Contact
                  </li>
                </Link>
                </div>
            
          : null}
      </ul>
    </header>
  );
}