export default function Menu() {
    return (
      <button aria-label="United States Family Marketing Button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-11"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    );
  }