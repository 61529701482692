import React, { useState } from "react";

export default function ProjectCard(props) {
  const [isShown, setIsShown] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      class="relative flex flex-col h-64 min-w-0 break-words bg-white bg-clip-border m-5"
    >
      <div class={"relative h-full overflow-hidden bg-cover " + props.css} >
        <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-gray-900 to-slate-800 opacity-80" />
        <div class="relative z-10 flex flex-col flex-auto h-full p-4">
          {isShown ? (
            <h5 class=" text-white 	 m-auto">{props.desc}</h5>
          ) : (
            <h5 class="text-xl  font-bold text-white m-auto">{props.title}</h5>
          )}
        </div>
      </div>
    </div>
  );
}
