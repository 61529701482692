import logo from "../logo.svg";
import { Helmet } from "react-helmet";
import SkillCard from "../components/card/skillcard";
import aj from "../assets/aj.jpg";


export default function Home() {



  return (
    <div className=" m-auto">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Full Stack Web Developer For Hire - ajnovobilski.com</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <h1 className="p-5 text-center font-bold text-2xl">
        Andrew J. Novobilski
      </h1>
      <img src={aj} className="h-64 rounded-xl m-auto" alt="logo" />
      <p className="font-bold text-xl text-center p-2">My Skills</p>
      <div className="lg:grid lg:grid-cols-4">
      <SkillCard title={'Mern'} css={'mern'} desc={'The Mern stack stands for MongoDB, Express, React, NodeJS. It is used for developing interactive CRUD apps.'}/>
      <SkillCard title={'Apache/Nginx'} css={'apache'} desc={'Apache2 is a webserver that allows you to host websites. Nginx is also a webserver and allows you to do the same. You can do many things with apache/nginx, including setup reverse proxies and load balancing.'}/>
      <SkillCard title={'React'} css={'react'} desc={'React is a next generation Javascript framework that was developed by engineers at Facebook. React offers superior performance and automatically packages code using webpack.'}/>
      <SkillCard title={'MongoDB'} css={'mongo'} desc={'MongoDB is a noSQL database used to read and write user data. MongoDB offers fast read and write speeds and can be setup with replica sets & sharded clusters. It also offers robust user authentication mechanisms, with built-in access controls.'}/>
      <SkillCard title={'WordPress'} css={'wp'} desc={'Wordpress is a template engine used for creating and managing client sites. People like using WordPress because of its versitile templates and backup utility.'}/>
      <SkillCard title={'MailCow'} css={'mc'} desc={'MailCow is a docker based email server that can be setup on a linux server. MailCow allows you to have full control over your email without using an email provider service like GoDaddy.'}/>
      <SkillCard title={'Photoshop'} css={'ps'} desc={'Photoshop is an image manipulation software developed by Adobe. It allows users to crop, recolor, scale and edit photographic images. Photoshop is widely used in graphic design applications.'}/>
      <SkillCard title={'GraphQL'} css={'gql'} desc={'GraphQL is a query language developed to sort through large sets of data. It allows you to write queries that return sets of data through a graphical interface. GraphQL queries may also be used to feed data into other sources.'}/>
      </div>
    </div>
  );
}
