import { useEffect, useState } from "react";
import DeskNav from "./desktop";
import MobileNav from "./mobile";

export default function Nav() {
  const [windowstate, setwindowstate] = useState();

  useEffect(() => {
    function nav() {
      function hr() {
        setwindowstate(window.innerWidth);
      }
      // await setnavstate(navdata)
      setwindowstate(window.innerWidth);
      window.addEventListener("resize", hr);
    }
    nav();
  });

  if (windowstate < 852) {
    return <MobileNav />;
  } else {
    return <DeskNav />;
  }
}
