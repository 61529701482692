import React from 'react';
import Router from './components/router';

import './App.css';
import Footer from './components/footer';

function App() {
  return (
    <div>
    <Router />
    <Footer/>
  </div>
  );
}

export default App;
