import { NavLink } from "react-router-dom";

export default function DeskNav() {
  return (
    <div className="w-full h-9 bg-blue-400 grid grid-cols-4">
      <NavLink
        to={"/home"}
        className={(navData) =>
          navData.isActive ? "m-auto text-white" : "m-auto hover:text-blue-200"
        }
      >
        Home
      </NavLink>
      <NavLink
        to={"/about"}
        className={(navData) =>
          navData.isActive ? "m-auto text-white" : "m-auto hover:text-blue-300"
        }
      >
        About
      </NavLink>
      <NavLink
        to={"/projects"}
        className={(navData) =>
          navData.isActive ? "m-auto text-white" : "m-auto hover:text-blue-300"
        }
      >
        Projects
      </NavLink>
      <NavLink
        to={"/contact"}
        className={(navData) =>
          navData.isActive ? "m-auto text-white" : "m-auto hover:text-blue-300"
        }
      >
        Contact
      </NavLink>
    </div>
  );
}
